:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
}

ul {
  padding-left: 2rem;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

strong {
  font-weight: bolder;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

:root {
  --mm-line-height: 20px;
  --mm-listitem-size: 44px;
  --mm-navbar-size: 44px;
  --mm-offset-top: 0;
  --mm-offset-right: 0;
  --mm-offset-bottom: 0;
  --mm-offset-left: 0;
  --mm-color-border: rgba(0, 0, 0, 0.1);
  --mm-color-button: rgba(0, 0, 0, 0.3);
  --mm-color-text: rgba(0, 0, 0, 0.75);
  --mm-color-text-dimmed: rgba(0, 0, 0, 0.3);
  --mm-color-background: #f3f3f3;
  --mm-color-background-highlight: rgba(0, 0, 0, 0.05);
  --mm-color-background-emphasis: rgba(255, 255, 255, 0.4);
  --mm-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

:root {
  --mm-iconbar-size: 50px;
}

:root {
  --mm-iconpanel-size: 50px;
}

:root {
  --mm-sidebar-collapsed-size: 50px;
  --mm-sidebar-expanded-size: 440px;
}

:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}

:root {
  --white: #fff;
  --black: #000;
  --anthrazit: #302E2F;
  --avocado: #4CB985;
  --lime: #B5FF00;
  --moos: #A4BE9A;
  --perl-grau: #CFDAD7;
  --sand: #88806A;
  --siena: #D46D22;
  --tannen-grun: #255038;
  --petrol: #005961;
  --violet: #6C5E8E;
  --grau-web: #F5F5F5;
  --font-color: var(--anthrazit);
  --link-color: var(--anthrazit);
  --grid-gutter-width: 40px;
  --height-header: 118px;
  --height-header-tablet: 99px;
  --height-header-mobile: 99px;
  --height-footer: 165px;
  --height-footer-tablet: 260px;
  --height-footer-mobile: 333px;
  --height-newsletter-block: 180px;
  --height-newsletter-block-tablet: 400px;
  --height-newsletter-block-mobile: 280px;
  --margin-small: calc(var(--grid-gutter-width) * 2);
  --margin-default: calc(var(--grid-gutter-width)*2 + var(--grid-gutter-width)*.5);
  --margin-big: calc(var(--grid-gutter-width) * 4);
  --tt-commons-pro: "TT Commons Pro";
  --tt-commons-pro-bold: "TT Commons Pro Bold";
  --regular: 400;
  --bold: 700;
  --height-slider: calc(100vh - var(--height-header));
  --height-slider-tablet: calc(100vh - var(--height-header-tablet));
  --height-slider-mobile: calc(100vh - var(--height-header-mobile));
}

.tc-website-megamenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  padding: 55px 0 65px;
  transform: translateY(-100%);
  z-index: 99;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
  opacity: 0;
}

.tc-website-megamenu .mega-menu-container {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x)*.5);
  padding-left: calc(var(--bs-gutter-x)*.5);
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

@media (min-width: 576px) {
  .tc-website-megamenu .mega-menu-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .tc-website-megamenu .mega-menu-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .tc-website-megamenu .mega-menu-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .tc-website-megamenu .mega-menu-container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .tc-website-megamenu .mega-menu-container {
    max-width: 1320px;
  }
}

.tc-website-megamenu .mega-menu-container header,
.tc-website-megamenu .mega-menu-container footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tc-website-megamenu .mega-menu-container header .menu-wrapper {
  display: flex;
  gap: calc(var(--grid-gutter-width)*.5);
  align-items: center;
}

.tc-website-megamenu .mega-menu-container header .menu-wrapper nav.tc-website-mainmenu ul.main-menu>li>ul.dropdown {
  display: none;
}



.tc-website-megamenu .mega-menu-container .section-wrapper {
  margin-top: 90px;
}

.tc-website-megamenu .mega-menu-container .section-wrapper .navigation-title {
  margin-bottom: 16px;
}

.tc-website-megamenu .mega-menu-container .section-wrapper .subpages {
  display: inline-block;
  height: 290px;
}

.tc-website-megamenu .mega-menu-container .hotline {
  position: absolute;
  bottom: 96px;
  right: 0;
}

.tc-website-megamenu .mega-menu-container .hotline div,
.tc-website-megamenu .mega-menu-container .hotline a {
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}

.tc-website-megamenu .mega-menu-container .hotline a {
  border-bottom: 1px solid var(--link-color);
  color: var(--link-color);
  text-decoration: none;
}

.tc-website-megamenu .mega-menu-container footer {
  margin-top: calc(var(--grid-gutter-width)*4);
}

.tc-website-megamenu .mega-menu-container footer nav.footer-menu ul {
  display: inline-flex;
  gap: 44px;
}

.tc-website-megamenu .mega-menu-container footer nav.footer-menu ul li a {
  font-family: var(--tt-commons-pro-bold);
  color: var(--anthrazit);
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  text-decoration: none;
  border-bottom: 1px solid rgba(0,0,0,0);
}

nav.tc-website-mainmenu ul.main-menu {
  display: flex;
  align-items: center;
  gap: 35px;
}

nav.tc-website-mainmenu ul.main-menu li a {
  font-family: var(--tt-commons-pro-bold);
  color: var(--black);
  font-size: 14px;
  text-decoration: none;
  line-height: normal;
  border-bottom: 1px solid rgba(0,0,0,0);
}

nav.tc-website-mainmenu ul.main-menu li.store-login a {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-size: 12px;
  background: var(--lime);
  border-bottom: 0 !important;
}

nav.tc-website-mainmenu ul.main-menu li ul.dropdown {
  display: none;
}

.tc-website-languagemenu .dropdown {
  position: relative;
  width: 62px;
}

.tc-website-languagemenu .dropdown .select-label {
  padding: 10px 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: normal;
  font-family: var(--tt-commons-pro-bold);
  background: var(--white);
  color: var(--anthrazit);
  font-size: 14px;
  z-index: 5;
  transform-style: preserve-3d;
  transform-origin: 50% 0%;
  text-transform: uppercase;
}

.tc-website-languagemenu .dropdown .select-label:after {
  content: url(../Images/Icons/chevron-down-black.svg);
}

.tc-website-languagemenu .dropdown .dropdown-list {
  position: absolute;
  top: 5px;
  width: 100%;
}

.tc-website-languagemenu .dropdown .dropdown-list li {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 2px;
  background-color: var(--lime);
  opacity: 1;
  padding: 4px 12px;
}

.tc-website-languagemenu .dropdown .dropdown-list li a {
  color: var(--anthrazit);
  font-size: 14px;
  text-decoration: none;
}

.tc-website-languagemenu .dropdown .dropdown-list li:nth-child(1) {
  z-index: 2;
}

.tc-website-languagemenu .dropdown .dropdown-list li:nth-child(2) {
  z-index: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (max-width: 991.98px) {
  .tc-website-languagemenu .dropdown {
    z-index: 2;
  }

  .tc-website-languagemenu .dropdown .select-label {
    background-color: var(--lime);
    border-radius: 30px;
  }

  .tc-website-languagemenu .dropdown .dropdown-list li {
    background-color: rgba(0,0,0,0);
  }
}

html {
  overflow-x: hidden;
  overflow-y: scroll !important;
  margin: 0 !important;
}

html.no-smooth-scroll {
  scroll-behavior: auto !important;
}

body {
  font-family: var(--tt-commons-pro);
  font-size: 16px;
  color: var(--font-color);
  line-height: 22px;
  overflow: hidden !important;
  background-color: #fff;
}

@media (max-width: 991.98px) {
  body {
    font-size: 14px;
    line-height: 18px;
  }
}

a {
  color: var(--link-color);
}

strong {
  font-family: var(--tt-commons-pro-bold);
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.global-container {
  position: relative;
}
