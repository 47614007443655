html {
    > body {
        > * {
            opacity: 1;
            transition: opacity .2s ease-out;
        }
    }

    &.loading {
        > body {
            > * {
                opacity: 0;
            }
        }
    }
}
